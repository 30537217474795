<template>
  <FormWrapper
      :form="form"
      :hideForm="hideForm"
      :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
      @getData="importAttendanceData(null, $event)"
      :hasPreConsultationValue="hasAttendanceValues('preConsultation')"
    >
    <b-row>
      <b-col>
        <b-row>
          <b-col cols="12">
            <p class="title-tags">Selecionados</p>
            <div class="tags">
              <div
                class="tag selected" 
                v-for="(opt, index) of form.value"
                :key="index"
                v-b-tooltip.hover :title="opt?.length >= 120 ? opt : ''"
              >
                {{ opt?.length >= 120 ? opt.slice(0, 120)+'...' : opt }}
                <Close @click="canEdit ? removeTag(index) : null" />
              </div>
            </div>
            <p class="title-tags">Sugestões</p>
            <div class="tags">
              <div
                class="tag"
                v-for="(opt, index) of options.filter(
                  opt => !form.value.includes(opt)
                )"
                :key="index"
                @click="canEdit ? addTag(opt) : null"
              >
                {{ opt }}
              </div>
              <input
                v-if="canEdit"
                class="input-tags"
                autocomplete="off"
                v-model="fields.custom"
                type="text"
                placeholder="Adicionar personalizado"
                @keyup.enter="addCustom"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    Close: () => import('@/assets/icons/close.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.habitosDeVida,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['hasPreConsultation', 'preConsultation'])
  },
  data() {
    return {
      fields: {
        custom: null
      },
      options: [
        'Pratica atividade física',
        'Dieta equilibrada',
        'Sedentarismo',
        'Tabagista',
        'Ex-tabagista',
        'Uso de drogas',
        'Má alimentação'
      ]
    }
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/habitosDeVida', ['handleFields', 'handleProps']),
    addTag(opt) {
      const value = [...this.form.value]
      value.push(opt)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    async importAttendanceData(medicalRecords = null, type) {
      const data = medicalRecords || this[type].medical_records
      const attendanceData = data.find(el => { return el.type === 'habitos-de-vida'})

      if (!attendanceData) return
      const value = [...this.form.value, ...attendanceData.value]
      this.handleProps({ key: 'value', value :[ ...new Set(value)] })
      await this.updateMedicalRecord(this.form)
    },
    removeTag(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    addCustom() {
      if (this.form.value.some(v => v.toLowerCase() === this.fields.custom)) {
        this.$toast.warning('Hábito já adicionado')
        return
      }
      const value = [...this.form.value]
      value.push(this.fields.custom)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields.custom = ''
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value = []
    }
  }
}
</script>
<style lang="scss" scoped>
.input-tags {
  margin: 0 8px 8px 0;
  height: 40px;
}

</style>
